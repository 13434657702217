<template>
  <portal to="body-top">
    <div class="w-100 d-flex justify-content-center my-1">
      <h4 class="font-weight-bolder">
        {{ orderPageTitle }}
      </h4>
    </div>
    <page-tabs />
  </portal>
</template>

<script>
import config from '../../config'
import PageTabs from './PageTabs.vue'

export default {
  name: 'PageTop',
  components: {
    PageTabs,
  },
  computed: {
    orderPageTitle() {
      return this.$store.state[this.MODULE_NAME].orderPageTitle
    },
  },
  mounted() {
    return this.$store.commit(`${this.MODULE_NAME}/SET_TRANSFER_ALLOWED_WAREHOUSES`, {})
  },
  setup() {
    const { MODULE_NAME } = config()

    return {
      MODULE_NAME,
    }
  },
}
</script>
